.container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 0;
}

.contentContainer {
  padding-left: 1rem;
}

.image {
  width: 100%;
}

@media (min-width: 800px) {
  .container {
    flex-direction: row;
  }

  .image {
    width: 448px;
  }
}
