.container {
  padding: 2rem;
}

@media (min-width: 800px) {
  .container {
    flex-direction: row;
  }

  .image {
    width: 448px;
  }
}
