.container {
  width: 100%;
  position: relative;
  line-height: 0;
}

.image {
  width: 100%;
  height: auto;
  max-height: 450;
}

.titleContainer {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title {
  text-shadow: 3px 3px 10px #333;
}
