.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contentContainer {
  height: 250px;
  display: flex;
  align-items: flex-end;
}

@media (min-width: 800px) {
  .container {
    flex-direction: row;
  }
}
